import { fetchAppNotifications } from '@/actions/appNotification';
import { fetchProjects } from '@/actions/project';
import AppNotifications from '@/components/AppNotifications/AppNotifications';
import LayoutPage from '@/components/Layout/LayoutPage';
import WrapperComponent from '@/components/WrapperComponent';
import { fetchAwaiting, fetchConfirmed } from '@/consultation/store';

import { LegacyRoute } from '../routesMiddleware';
import Dashboard from './Dashboard';

const route: LegacyRoute = {
  path: '/dashboard',
  title: 'Dashboard',
  element: <WrapperComponent />,
  async action({ store }) {
    const cursor = '';

    const promises = [store.dispatch(fetchAppNotifications())];

    store.dispatch(fetchAwaiting(cursor, 1000, false, 'dashboardAwaiting'));
    store.dispatch(fetchConfirmed(cursor, 1000, false, 'dashboardConfirmed'));
    store.dispatch(
      fetchProjects({
        collection: 'dashboard',
        pageSize: 1000,
        openOnly: true,
        memberOnly: true,
      })
    );

    await Promise.all(promises);

    await AppNotifications.prefetch(store);
    document.title = 'Dashboard';
    return (
      <LayoutPage showNav selected="dashboard">
        <Dashboard />
      </LayoutPage>
    );
  },
};

export default route;
