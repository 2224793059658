import MenuItem from '@mui/material/MenuItem';
import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from '@/store';
import { black } from '@/theme/colors';

import DeleteProjectDialog from '../DeleteDialog/DeleteProjectDialog';
import FAIcon from '../Icon/FAIcon';
import IconMenu from '../IconMenu';
import EditMembers from './Dialog';

const SettingsMenu = (props: any) => {
  const navigate = useNavigate();
  const [dialogs, setDialogs] = useState<{ members?: boolean }>({});
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const openDialog = useCallback(() => setDialogs({ members: true }), []);
  const closeDialog = useCallback(() => setDialogs({ members: false }), []);
  const confirmDelete = useCallback(() => setDeleteConfirmationOpen(true), []);
  const closeConfirmDelete = useCallback(() => setDeleteConfirmationOpen(false), []);

  const { viewer, project, showDelete } = props;
  const members = useMemo(
    () =>
      project.members
        .filter((m: any) => ['active', 'denied'].includes(m.state))
        .sort((a: any, b: any) => {
          const roles = ['manager', 'associate', 'owner', 'member', 'viewer'];
          return roles.indexOf(a.role) - roles.indexOf(b.role);
        }),
    [project.members]
  );

  return (
    <div>
      <IconMenu
        iconElement={
          <span style={{ padding: 12 }}>
            <FAIcon icon="cog" color={black} />
          </span>
        }
      >
        <MenuItem key="managerMembers" onClick={openDialog}>
          Manage Members
        </MenuItem>
        <MenuItem
          key="addExpertRequest"
          onClick={() => navigate(`/request_expert?project_id=${project.id}`)}
        >
          Add Expert Request
        </MenuItem>
        {showDelete && (
          <MenuItem
            key="deleteProject"
            onClick={confirmDelete}
            disabled={project.expert_requests.some((e: any) => e.stats && e.stats.calls > 0)}
          >
            Delete Project
          </MenuItem>
        )}
      </IconMenu>
      <EditMembers
        viewer={viewer}
        open={dialogs.members}
        onClose={closeDialog}
        project={project}
        initialValues={{ members }}
      />
      <DeleteProjectDialog
        projectId={project.id}
        onConfirm={closeConfirmDelete}
        onCancel={closeConfirmDelete}
        open={deleteConfirmationOpen}
        returnTo="/expert_requests"
      />
    </div>
  );
};

export default connect((state: RootState) => ({
  viewer: state.viewer,
}))(SettingsMenu);
