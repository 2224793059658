import { ValidationErrors } from 'final-form';
import React, { useCallback, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';

import { renderTemplate } from '@/actions/messageTemplate';
import { notify } from '@/actions/ui';
import Button from '@/components/Button/Button';
import Dialog from '@/components/Dialog';
import { Select } from '@/components/FormAdapters';
import { Viewer } from '@/core/viewer';
import { addExpertRequestCandidates } from '@/expertrequest/store';
import { selectProfile } from '@/search/store';
import { RootState } from '@/store';
import { sortBy } from '@/utils';

import { AddToStageField } from './FormFields/AddToStage';
import { EmailTemplateField } from './FormFields/EmailTemplate';
import { ExpertsToAddField } from './FormFields/ExpertsToAdd';
import { IncludeInvitationEmailField } from './FormFields/IncludeInvitationEmail';

export type Profile = { [key: string]: { error_code: string; warning_code: string } };
type Option = {
  value: string;
  label: string;
};

export interface Values {
  expert_request_id: string;
  send_invitation_email: boolean;
  invitation_email_subject: string;
  invitation_email_body: string;
  candidate_state: string;
}

type AddToExpertRequestDialogProps = {
  open: boolean;
  viewer: Viewer;
  expertRequests: any[];
  profiles: Profile[];
  onClose: () => void;
  onExpertAdd?: (data: any) => void;
  notify: (message: string, type?: string) => void;
  addExpertRequestCandidates: (args: any) => Promise<any>;
};

const AddToExpertRequestDialog: React.FC<AddToExpertRequestDialogProps> = ({
  open,
  viewer,
  expertRequests,
  profiles,
  onClose,
  onExpertAdd,
  notify,
  addExpertRequestCandidates,
}) => {
  const [profileInvitationExample, setProfileInvitationExample] = useState<Profile>();
  const [validationResult, setValidationResult] = useState<{
    results: any[];
    success?: boolean;
  } | null>(null);

  const initialValues = useMemo(
    () => ({
      candidate_state: viewer.admin ? 'contacted' : 'matched',
      send_invitation_email: viewer.admin,
    }),
    [viewer.admin]
  );

  const options: Option[] = useMemo(
    () =>
      expertRequests.sort(sortBy('name')).map((r: any) => ({
        value: r.id,
        label: r.name,
      })),
    [expertRequests]
  );

  const handleProfileRemove = useCallback(
    (selectedProfile: any) => {
      selectProfile(selectedProfile, false);

      if (profileInvitationExample && selectedProfile.id === profileInvitationExample.id) {
        const newExample = profiles.find((p: any) => p.id !== selectedProfile.id);
        setProfileInvitationExample(newExample);
      }
    },
    [profileInvitationExample, profiles]
  );

  const validationResultsByProfile = useCallback(() => {
    const profiles: Profile = {};
    ((validationResult && validationResult.results) || []).forEach((r) => {
      profiles[r.profile_id] = {
        error_code: r.error_code,
        warning_code: r.warning_code,
      };
    });
    return profiles;
  }, [validationResult]);

  const onSubmit = useCallback(
    async (values: Values) => {
      const expertRequestId = values.expert_request_id;
      const sendInvitationEmail = values.send_invitation_email;
      const invitationEmailSubject = values.invitation_email_subject;
      const invitationEmailBody = values.invitation_email_body;
      const candidateState = values.candidate_state;

      const expertRequest = expertRequests.find((p: any) => p.id === expertRequestId);

      const expertsText = profiles.length === 1 ? profiles[0].name : `${profiles.length} experts`;

      try {
        const data = await addExpertRequestCandidates({
          expertRequestId,
          dryRun: false,
          sendInvitationEmail,
          invitationEmailSubject,
          invitationEmailBody,
          state: candidateState,
          profileIds: profiles.map((p) => p.id),
        });

        setValidationResult(data);
        notify(`${expertsText} added to ${expertRequest.name}.`);
        if (onExpertAdd) onExpertAdd(data);

        onClose();
      } catch (err) {
        notify(`${expertsText} could not be added to ${expertRequest.name}.`, 'error');
        return err;
      }
    },
    [expertRequests, profiles, addExpertRequestCandidates, notify, onExpertAdd, onClose]
  );

  const validate = useCallback((values: Values): ValidationErrors => {
    return {
      expert_request_id: values?.expert_request_id
        ? undefined
        : 'Need to specify an Expert Request',
      candidate_state: values?.candidate_state ? undefined : 'Need to select a state',
    };
  }, []);

  const validationText = useCallback(
    (level: any) => {
      const prop = `${level}_code`;

      const getMessageCount = (error: any) =>
        (validationResult?.results || []).filter((r: any) => r[prop] === error).length;

      const messages = [];

      const marketplaceErrors = getMessageCount('out_of_marketplace');
      if (marketplaceErrors >= 1) {
        messages.push(
          `The request does not match the marketplace preferences of ${marketplaceErrors} expert(s) (highlighted)`
        );
      }

      const selfServiceErrors = getMessageCount('self_service_not_available');
      if (selfServiceErrors > 1) {
        messages.push(
          `${selfServiceErrors} experts are not available for self service (highlighted)`
        );
      }
      if (selfServiceErrors > 0) {
        messages.push('The expert is not available for self service');
      }

      if (messages.length > 0) {
        return messages.map((m) => <div key={m}>{m}</div>);
      }
    },
    [validationResult?.results]
  );

  const resultsByProfile = validationResultsByProfile();
  const warningText = validationText('warning');
  const errorText = validationText('error');

  return (
    <Dialog
      //modal
      open={open}
      user={profiles.length === 1 ? profiles[0] : undefined}
      title="Add Experts to Request"
      //saveLabel="Save"
    >
      <Form
        validate={validate}
        onSubmit={onSubmit}
        initialValues={initialValues}
        profiles={profiles}
        render={({ handleSubmit, values, form, submitting, pristine, valid }) => (
          <form onSubmit={handleSubmit} onReset={() => form.reset()}>
            <Field
              autocomplete
              name="expert_request_id"
              component={Select}
              label="Expert Request"
              options={options}
            />
            <ExpertsToAddField
              warningText={warningText}
              errorText={errorText}
              resultsByProfile={resultsByProfile}
              profiles={profiles}
              handleProfileRemove={handleProfileRemove}
            />
            <AddToStageField isAdmin={!!viewer.admin} />
            <IncludeInvitationEmailField
              isAdmin={!!viewer.admin}
              checked={values.send_invitation_email}
            />
            <EmailTemplateField
              isAdmin={!!viewer.admin}
              profiles={profiles}
              form={form}
              values={values}
              profileInvitationExample={profileInvitationExample}
              setProfileInvitationExample={setProfileInvitationExample}
              senderId={viewer.id}
            />
            <div className="mt-12 flex justify-between">
              <Button id="cancelDialogButton" variant="text" onClick={onClose} size="medium">
                Dismiss
              </Button>
              <Button
                disabled={submitting || pristine || !valid}
                type="submit"
                size="medium"
                submitting={submitting}
              >
                Confirm
              </Button>
            </div>
          </form>
        )}
      />
    </Dialog>
  );
};

export default connect(
  (state: RootState) => {
    return {
      viewer: state.viewer,
      expertRequests: state.expertRequests.open?.edges.map((e: any) => e.node) || [],
    };
  },
  {
    notify,
    selectProfile,
    addExpertRequestCandidates,
    renderTemplate,
  }
)(AddToExpertRequestDialog);
