import { fetchCountries } from '@/actions/country';
import { fetchAllGroups } from '@/actions/group';
import { track } from '@/actions/tracking';
import ConsultationsStarting from '@/components/ConsultationsStarting';
import LayoutPage from '@/components/Layout/LayoutPage';
import WrapperComponent from '@/components/WrapperComponent';
import { isEnterpriseUser } from '@/core/group';
import { toDateBoundString } from '@/core/search';
import Experts from '@/search/components/Experts';
import { search, setSearchResults } from '@/search/store';
import { Query } from '@/search/store';
import { fetchUser } from '@/store/user';

import UpsellPromo from '../components/UpsellPromo';

function toBool(value: any) {
  if (value === undefined || value === null) return value;
  return value === '1' || value === 1 || value.toString().toLowerCase() === 'true';
}

function toArray(value: any) {
  if (!value) return [];
  return Array.isArray(value) ? value : [value];
}

export default {
  path: '/search',
  element: <WrapperComponent />,
  async action({ store, query: queryRaw }: any) {
    const { viewer } = store.getState();

    if (!isEnterpriseUser(viewer)) {
      store.dispatch(track('promo.show.search'));
      document.title = 'Upgrade to Enterprise';
      return (
        <LayoutPage showNav verticalCenter>
          <UpsellPromo />
        </LayoutPage>
      );
    }

    const totalFiltersApplied = Object.keys(queryRaw).filter(
      (k) => !['q', 'query', 'ascending'].includes(k)
    ).length;

    const query = new Query({
      text: queryRaw.query || queryRaw.q,
      sort: queryRaw.sort,
      ascending: toBool(queryRaw.asc),
      mustHaveEmail: toBool(queryRaw.email),
      profileType: queryRaw.profile_type,
      expertStates: toArray(queryRaw.expert_states),
      expertHistory: toArray(queryRaw.expert_history),
      languages: toArray(queryRaw.languages),
      countries: toArray(queryRaw.countries),
      industries: toArray(queryRaw.industries),
      agentIds: toArray(queryRaw.agents),
      networkIds: toArray(queryRaw.networks),
      organizationsCurrentRestriction: queryRaw.organizations_restrict,
      locationsCurrentRestriction: queryRaw.locations_restrict,
      jobTitlesCurrentRestriction: queryRaw.job_titles_restrict,
      organizations:
        queryRaw.organizations && queryRaw.organizations.map(toDateBoundString).filter(Boolean),
      locations: queryRaw.locations && queryRaw.locations.map(toDateBoundString).filter(Boolean),
      jobTitles: queryRaw.job_titles && queryRaw.job_titles.map(toDateBoundString).filter(Boolean),
      consultationsWith: toArray(queryRaw.consultations_with),
      educationDegrees: toArray(queryRaw.education_degrees),
      educationFos: toArray(queryRaw.education_fos),
      groupKeywordIds: toArray(queryRaw.group_keyword_ids),
      keywords: toArray(queryRaw.keywords),
      marketplacePreference: queryRaw.marketplace_preference,
    });

    try {
      await Promise.all(
        [
          setSearchResults({
            query,
          }),
        ].map(store.dispatch)
      );
    } catch (err) {
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      if (!err.query) throw err;
    }

    [
      // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
      search(query),
      fetchCountries(),
      fetchAllGroups({ collection: 'networks', internalNetwork: true }),
      fetchUser(viewer.username, {
        groups: true,
        profileKeywordsDefinition: true,
      }),
    ].forEach(store.dispatch);

    document.title = 'My Network';
    return (
      <LayoutPage showNav selected="my-network">
        <ConsultationsStarting />
        {
          // @ts-expect-error
          <Experts totalFiltersApplied={totalFiltersApplied} />
        }
      </LayoutPage>
    );
  },
};
