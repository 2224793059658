import { AutocompleteFreeSoloValueMapping } from '@mui/material';
import MuiAutocomplete, {
  AutocompleteValue,
  AutocompleteProps as MuiAutocompleteProps,
  createFilterOptions,
} from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormHelperText, { FormHelperTextProps } from '@mui/material/FormHelperText';
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Popper from '@mui/material/Popper';
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select';
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import isEqual from 'lodash.isequal';
import { useCallback, useEffect, useRef, useState } from 'react';

export interface OptionPair<Value> {
  value: Value;
  label: string;
}

const defaultGetOptionValue = <Value,>(o: Value): any => (o as OptionPair<Value>)?.value || '';
const defaultGetOptionLabel = <Value, FreeSolo>(
  o: Value | AutocompleteFreeSoloValueMapping<FreeSolo>
): string => (o as OptionPair<Value>)?.label || '';
const defaultIsOptionEqualToValue = <Value,>(o: Value, v: Value) =>
  (o as any)?.value === (v as any)?.value;

const defaultFilterOptions = <Value,>(limit: number, getOptionLabel: (option: Value) => string) =>
  createFilterOptions<Value>({
    limit,
    trim: true,
    stringify: getOptionLabel,
  });

type MarginType = 'none' | 'dense' | 'normal';

export interface AutocompleteProps<
  Value,
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean,
> extends Omit<
    MuiAutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>,
    'renderInput' | 'options'
  > {
  rawValueOnChange?: boolean;
  getOptionValue?: (option: Value) => any;
  limit?: number;
  sort?: boolean;
  selectOnExactMatchInMultiMode?: boolean;
  TextFieldProps?: MuiTextFieldProps;
  label?: string;
  placeholder?: string;
  margin?: MarginType;
  loading?: boolean;
  options: readonly any[];
}

export const Autocomplete = <
  Value,
  Multiple extends boolean = false,
  DisableClearable extends boolean = false,
  FreeSolo extends boolean = false,
>({
  // Autocomplete props
  value,
  multiple,
  options = [],
  onChange,
  rawValueOnChange,
  getOptionValue = defaultGetOptionValue,
  getOptionLabel = defaultGetOptionLabel,
  isOptionEqualToValue = defaultIsOptionEqualToValue,
  limit = 10,
  sort = true,
  freeSolo,
  selectOnExactMatchInMultiMode = false,

  // TextInput props
  TextFieldProps = {},
  label,
  placeholder,
  margin,
  loading = false,
  ...other
}: AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>) => {
  // KT: TypeScript doesn't seem to be able to infer types correctly when checking 'multiple'
  type ValueArray = Array<Value | AutocompleteFreeSoloValueMapping<FreeSolo>>;

  const autocompleteRef = useRef<HTMLDivElement>(null);
  const [autocompleteWidth, setAutocompleteWidth] = useState(0);

  const resizeAutocomplete = useCallback(() => {
    if (!autocompleteRef.current) return;
    setAutocompleteWidth(autocompleteRef.current.clientWidth);
  }, [autocompleteRef, setAutocompleteWidth]);

  useEffect(() => {
    resizeAutocomplete();
    window.addEventListener('resize', resizeAutocomplete);
    return () => window.removeEventListener('resize', resizeAutocomplete);
  }, [resizeAutocomplete]);

  const onChangeFixed = useCallback(
    (_event: any, selected: any) => {
      const newValue = multiple ? selected.map(getOptionValue) : getOptionValue(selected) || '';

      // Same format used for Redux
      if (rawValueOnChange) {
        // @ts-expect-error
        onChange(newValue);
      } else {
        // Use same format as the Select component
        // @ts-expect-error
        onChange({ target: { value: newValue } });
      }
    },
    [getOptionValue, multiple, onChange, rawValueOnChange]
  );

  // Clear the selection if input is empty and control is in single mode
  // Append(if in multiple mode) or override the selection if exact match
  const onInputChange = useCallback(
    (event: any, inputValue: any) => {
      if (!multiple && inputValue === '') {
        return onChangeFixed(event, '');
      }

      if (selectOnExactMatchInMultiMode || !multiple) {
        const val = options.find(
          (c: any) =>
            (c.name && c.name.toLowerCase() === inputValue.toLowerCase()) ||
            (c.label && c.label.toLowerCase() === inputValue.toLowerCase())
        );

        if (val) {
          if (!multiple || !(value as ValueArray).includes(val)) {
            onChangeFixed(event, multiple ? [...(value as ValueArray), val] : val);
          }
        }
      }
    },
    [multiple, selectOnExactMatchInMultiMode, onChangeFixed, options, value]
  );

  if (sort) {
    options = [...options].sort((a: any, b: any) => {
      const aValue = getOptionLabel(a).trim().toUpperCase();
      const bValue = getOptionLabel(b).trim().toUpperCase();
      return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
    });
  }

  // Internally the Autocomplete value must be the selected options in order
  // to work properly, we use isEqual to avoid the need for reference
  // based equality when value is an object

  const filteredValue = (
    multiple
      ? options.filter((o: Value) =>
          (value as ValueArray)?.find((v: any) => isEqual(v, getOptionValue(o)))
        )
      : // KT: note this null won't work if DisableClearable is true
        options.find((o: Value) => isEqual(value, getOptionValue(o))) || null
  ) as AutocompleteValue<Value, Multiple, DisableClearable, FreeSolo>;

  return (
    <MuiAutocomplete
      multiple={multiple}
      options={options}
      onChange={onChangeFixed}
      onInputChange={onInputChange}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      filterOptions={defaultFilterOptions<Value>(limit, getOptionLabel)}
      PopperComponent={(props) => (
        <Popper {...props} style={{ width: autocompleteWidth }} placement="bottom-start" />
      )}
      ref={autocompleteRef}
      freeSolo={freeSolo}
      value={freeSolo ? value : filteredValue}
      renderInput={(params) =>
        loading ? (
          <MuiTextField
            defaultValue="Loading..."
            onClick={(e) => e.stopPropagation()}
            margin={margin || TextFieldProps.margin}
          />
        ) : (
          <MuiTextField
            {...params}
            {...TextFieldProps}
            InputProps={{
              ...params.InputProps,
              ...(TextFieldProps.InputProps || {}),
            }}
            inputProps={{
              ...params.inputProps,
              ...(TextFieldProps.inputProps || {}),
            }}
            // When using redux form the following props are not passed directly
            // and instead are placed in to TextFieldProps
            onClick={(e) => e.stopPropagation()}
            label={label || TextFieldProps.label}
            placeholder={placeholder || TextFieldProps.placeholder}
            margin={margin || TextFieldProps.margin}
          />
        )
      }
      {...other}
    />
  );
};
Autocomplete.displayName = 'Autocomplete';

const useStyles = makeStyles({
  checkbox: {
    padding: 0,
    marginRight: 5,
  },
});

export type SelectProps<
  Value,
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean,
> = MuiSelectProps<Value> & {
  error?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  margin?: MarginType;

  formHelperTextProps?: FormHelperTextProps;
  label?: string;
  helperText?: string;

  labelProps?: InputLabelProps;

  menuItemClasses?: any;
  displayCheckbox?: boolean;
  options: readonly any[];
  includeEmpty?: boolean;
  getOptionValue?: (option: Value) => any;
  getOptionLabel?: (option: Value | AutocompleteFreeSoloValueMapping<FreeSolo>) => string;
  sort?: boolean;
  native?: boolean;

  value?: ('' | Value) & AutocompleteValue<Value, Multiple, DisableClearable, FreeSolo>;
  multiple?: Multiple;
  onChange: (event: any) => void;
};

export const Select = <
  Value,
  Multiple extends boolean = false,
  DisableClearable extends boolean = false,
  FreeSolo extends boolean = false,
>({
  // FormControl props
  error,
  disabled,
  fullWidth = true,
  margin,

  // FormControl children props
  formHelperTextProps,
  label,
  helperText,

  // Custom Label props
  labelProps,

  // Custom Select props
  menuItemClasses,
  displayCheckbox,
  options,
  includeEmpty,
  getOptionValue = defaultGetOptionValue,
  getOptionLabel = defaultGetOptionLabel,
  sort = true,
  native,

  // Material UI Select props
  value,
  multiple,
  onChange,
  ...other
}: SelectProps<Value, Multiple, DisableClearable, FreeSolo>) => {
  type ValueArray = Array<Value>;

  const classes = useStyles();

  const sortedOptions = [...options];
  if (sort) {
    sortedOptions.sort((a: Value, b: Value) => {
      const aValue = getOptionLabel(a).trim().toUpperCase();
      const bValue = getOptionLabel(b).trim().toUpperCase();
      return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
    });
  }

  if (native) {
    const nativeOnChange = ({ target: { value } }: any) =>
      value ? onChange([value]) : onChange([]);

    return (
      <MuiSelect<Value>
        native
        fullWidth={fullWidth}
        value={multiple && (value as ValueArray).length ? (value as ValueArray)[0] : ''}
        onChange={nativeOnChange}
      >
        <option value="" disabled={!includeEmpty} />
        {sortedOptions.map((op: Value, i: number) => (
          <option key={i} value={getOptionValue(op)}>
            {getOptionLabel(op)}
          </option>
        ))}
      </MuiSelect>
    );
  }

  const menuItems = sortedOptions.map((o: any) => {
    const itemValue = getOptionValue(o);
    const itemLabel = getOptionLabel(o);

    const isSelected = multiple
      ? (value as ValueArray).some((v: any) => v === itemValue)
      : value === itemValue;

    return (
      <MenuItem key={itemValue} value={itemValue} classes={menuItemClasses}>
        {displayCheckbox && <Checkbox classes={{ root: classes.checkbox }} checked={isSelected} />}
        {itemLabel}
      </MenuItem>
    );
  });

  if (includeEmpty) {
    menuItems.unshift(<MenuItem key="" value="" />);
  }

  return (
    <FormControl margin={margin} error={error} disabled={disabled} fullWidth={fullWidth}>
      {label && <InputLabel {...labelProps}>{label}</InputLabel>}
      <MuiSelect<Value> value={value} multiple={multiple} onChange={onChange} {...other}>
        {menuItems}
      </MuiSelect>
      {helperText && <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

interface SelectWithNativeSwitchOwnProps {
  autocomplete?: boolean;
  useNativeSelectForMobile?: boolean;
}

export type SelectWithNativeSwitchProps<
  Value,
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean,
> = (
  | SelectProps<Value, Multiple, DisableClearable, FreeSolo>
  | AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>
) &
  SelectWithNativeSwitchOwnProps;

const SelectWithNativeSwitch = <
  Value,
  Multiple extends boolean = false,
  DisableClearable extends boolean = false,
  FreeSolo extends boolean = false,
>({
  autocomplete = false,
  useNativeSelectForMobile = false,
  ...other
}: SelectWithNativeSwitchProps<Value, Multiple, DisableClearable, FreeSolo>) => {
  const theme = useTheme();
  const mq = useMediaQuery(theme.breakpoints.down('md'));
  const native = useNativeSelectForMobile ? mq : false;

  if (autocomplete && !native) {
    const otherTyped = other as AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo>;
    return <Autocomplete<Value, Multiple, DisableClearable, FreeSolo> {...otherTyped} />;
  } else {
    const otherTyped = other as SelectProps<Value, Multiple, DisableClearable, FreeSolo>;
    return <Select<Value, Multiple, DisableClearable, FreeSolo> native={native} {...otherTyped} />;
  }
};

export default SelectWithNativeSwitch;
