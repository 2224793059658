import {
  ApolloClient,
  ApolloError,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { SentryLink } from 'apollo-link-sentry';
import { DocumentNode, parse, print } from 'graphql';

import { ErrorCode } from './apiErrorCodes';

export { default as ERROR_CODES } from './apiErrorCodes';
export type { ErrorCode } from './apiErrorCodes';

const PERMISSION_ERROR_MESSAGE = "You don't have permission to perform this action.";
const OTP_ERROR_MESSAGES = {
  otp_required: 'otp required',
  otp_enrollment_required: 'otp enrollment required',
  otp_invalid: 'invalid otp',
};

export function hasErrorCode(err: any, code: ErrorCode): boolean {
  if (!(err instanceof ApolloError)) return false;

  for (const error of err.graphQLErrors) {
    if (error?.extensions?.code === code) return true;
  }
  return false;
}

function hasError(errors: any, message: any) {
  return (errors || []).some((e: any) => e.message && e.message.startsWith(message));
}

export class TransportError extends Error {
  extra: any;
  constructor(message: any, payload?: any) {
    super(message);
    this.name = 'TransportError';
    this.extra = { payload };
  }
}

interface UnauthorizedResponse {
  code: ErrorCode;
  reason?: string;
  remaining_attempts?: number;
  mfa_group_id?: string;
  otp_method?: string;
  otp_masked_address?: string;
}

export class UnauthorizedError extends Error {
  isPermissionError: boolean;
  code: ErrorCode;
  remainingAttempts?: number;
  mfaGroupId?: string;
  otpMethod?: string;
  otpMaskedAddress?: string;

  constructor(message: string, response: UnauthorizedResponse) {
    super(message);
    this.name = this.constructor.name;
    this.code = response.code;
    this.remainingAttempts = response.remaining_attempts;
    this.mfaGroupId = response.mfa_group_id;
    this.otpMethod = response.otp_method;
    this.otpMaskedAddress = response.otp_masked_address;
    this.isPermissionError = true;
  }
}

export class APIError extends Error {
  extra: any;
  isPermissionError: any;
  query: any;
  rawData: any;
  rawError: any;
  variables: any;
  constructor(errors: any, data: any, query: any, variables: any) {
    const payload = { query, variables };
    const { message } = errors[0];
    super(`GraphQL Error: ${message}`);
    this.rawError = errors;
    this.rawData = data;
    this.query = query;
    this.extra = { payload, errors };
    this.variables = variables;
    this.isPermissionError = hasError(errors, PERMISSION_ERROR_MESSAGE);
  }
}

export function basicAuth(username: any, password: any) {
  const token = btoa(`${username}:${password}`);
  return `Basic ${token}`;
}

export function bearerAuth(token: any) {
  return `Bearer ${token}`;
}

type Query = string | DocumentNode;
type Variables = Record<string, any>;

export class GraphQLClient {
  client: ApolloClient<NormalizedCacheObject>;
  url: string;

  constructor(url: string, auth?: any, { otp, extraHeaders = {} }: any = {}) {
    const httpLink = new HttpLink({ uri: `${url}/graphql` });
    // see: https://www.apollographql.com/docs/react/api/link/apollo-link-remove-typename
    const removeTypenameLink = removeTypenameFromVariables();

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          ...(auth && { authorization: auth }),
          ...(otp && { 'x-onfrontiers-auth-otp': otp }),
          ...extraHeaders,
        },
      };
    });

    this.url = url;
    this.client = new ApolloClient({
      link: from([removeTypenameLink, authLink, new SentryLink(), httpLink]),
      cache: new InMemoryCache(),
      devtools: {
        enabled: import.meta.env.MODE === 'development',
      },
    });
  }

  query(query: Query, variables?: Variables) {
    return this.send(query, variables);
  }

  mutate(query: Query, variables?: Variables) {
    if (typeof query === 'string' && !query.startsWith('mutation ')) {
      query = `mutation _${query}`;
    }
    return this.send(query, variables);
  }

  async send(query: Query, variables?: Variables) {
    if (typeof query === 'string') {
      query = parse(query);
    }

    const isMutation = query.definitions.some(
      (def: any) => def.kind === 'OperationDefinition' && def.operation === 'mutation'
    );

    try {
      const result = isMutation
        ? await this.client.mutate({ mutation: query, variables })
        : await this.client.query({ query, variables });
      return structuredClone(result.data);
    } catch (err: any) {
      const serverErr = err.networkError;
      let errors = err.graphQLErrors;
      if (serverErr) {
        const result = serverErr.result;
        switch (serverErr.statusCode) {
          case 401: {
            let reason = result?.reason || '';
            for (const [key, message] of Object.entries(OTP_ERROR_MESSAGES)) {
              if (reason.startsWith(message)) {
                reason = key;
                break;
              }
            }

            throw new UnauthorizedError(reason, result);
          }
          case 422:
            errors = result.errors;
            break;
          default:
            throw new TransportError(err.message);
        }
      }

      if (errors) {
        throw new APIError(structuredClone(errors), null, print(query), variables);
      }

      throw err;
    }
  }

  fromAuth = (auth: any, options?: any) => new GraphQLClient(this.url, auth, options);
}
