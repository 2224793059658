import { Button as MaterialButton } from '@mui/material';
import arrayMutators from 'final-form-arrays';
import setFieldData from 'final-form-set-field-data';
import moment from 'moment-timezone';
import { useCallback, useEffect, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';

import { popup } from '@/actions/ui';
import Button from '@/components/Button/Button';
import ConsultationDates from '@/components/ConsultationDates';
import Dialog from '@/components/Dialog';
import DurationPicker from '@/components/DurationPicker';
import ExpertPicture from '@/components/ExpertPicture';
import minimumTimeNoticePopup from '@/components/RequestConsultation/minimumTimeNoticePopup';
import { getSuggestedTimes, minimumTimeNotice, validate } from '@/consultation';
import Duration from '@/core/duration';
import { TimeRange } from '@/core/time';

import s from './Reschedule.module.scss';

function Title({ user, isViewerExpert }: any) {
  const userName =
    isViewerExpert && !user ? 'Confidential client' : user ? user.name : 'User not available';

  const title = (
    <div>
      {userName}
      {user && <span className={s.userType}>&nbsp;({isViewerExpert ? 'Client' : 'Expert'})</span>}
    </div>
  );

  return (
    <div className={s.dialogTitle}>
      <div className={s.photoContainer}>
        <ExpertPicture user={user} size={50} />
      </div>

      <div className={s.titleContainer}>
        <div className={s.title}>{title}</div>
        <div className={s.subTitle}>Rescheduling Request</div>
      </div>
    </div>
  );
}

function RescheduleDialog({
  // Props
  viewer,

  open,
  user,
  userTimezone,
  creditRate,
  isFixedRate,
  isViewerExpert,
  rejectedTimes,
  proposedTimes,
  showDuration,
  onCancel,
  timesSuggested,
  label = 'Suggested new time(s)',
  description = 'If the change is accepted, your call will be confirmed.',

  // Final Form
  form,

  values,
  submitting,
  handleSubmit,
  dirty,
  pristine,
}: any) {
  const handleCancel = useCallback(() => {
    form.reset();
    if (onCancel) onCancel();
  }, [form, onCancel]);

  useEffect(() => {
    const { dates } = values;
    const duration = Duration.parse(values.duration);

    dates.forEach((date: any, i: any) => {
      let warning;
      if (date) {
        const end = moment(date).add(duration.milliseconds(), 'ms');
        const range = new TimeRange(moment(date), end);

        const alreadySuggested = proposedTimes.concat(rejectedTimes || []).find((r: any) => {
          const rejectedStart = moment(r);
          const rejectedEnd = rejectedStart.add(duration.milliseconds(), 'ms');
          const rejectedRange = new TimeRange(rejectedStart, rejectedEnd);
          return range.overlaps(rejectedRange);
        });

        if (alreadySuggested) warning = 'This time was already suggested.';
      }

      form.mutators.setFieldData(`dates[${i}]`, { warning });
    });
  }, [form.mutators, proposedTimes, rejectedTimes, values, values.dates]);

  return (
    <Dialog open={open} scroll="body">
      <Title user={user} isViewerExpert={isViewerExpert} />

      {description && <div className={s.message}>{description}</div>}

      <form onSubmit={handleSubmit}>
        {showDuration && (
          <Field
            component={DurationPicker}
            name="duration"
            showCredits={!isFixedRate}
            creditRate={creditRate}
          />
        )}

        <ConsultationDates
          name="dates"
          displayWarning={dirty}
          viewer={viewer}
          userName={user ? user.first_name : 'the client'}
          userTimezone={userTimezone}
          label={label}
          style={{ marginTop: 20 }}
        />

        <div className={s.actions}>
          <MaterialButton onClick={handleCancel}>Cancel</MaterialButton>

          <Button disabled={(pristine && !timesSuggested) || submitting} size="small" type="submit">
            Request Change
          </Button>
        </div>
      </form>
    </Dialog>
  );
}

function Reschedule({
  // Props
  open,

  user,
  userTimezone,
  creditRate,
  unpaid,
  isViewerExpert,
  rejectedTimes,
  proposedTimes,
  duration,
  showDuration,
  onCancel,
  onConfirm,
  description,
  label,
  isWrittenConsultation,

  // Redux Actions
  popup,

  // Redux State
  viewer,
}: any) {
  const handleSubmit = useCallback(
    (values: any) => {
      const dates = values.dates.filter(Boolean);

      const doReschedule = () => onConfirm(values);

      // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
      if (minimumTimeNotice(dates, viewer.timezone)) {
        doReschedule();
      } else {
        minimumTimeNoticePopup(popup, isViewerExpert, doReschedule);
      }
    },
    [isViewerExpert, onConfirm, popup, viewer.timezone]
  );

  let newProposedTimes: any = null;
  const suggestTimes = proposedTimes.length === 0;

  if (isWrittenConsultation) {
    newProposedTimes = [moment().add(7, 'd').startOf('day')];
  } else {
    newProposedTimes = proposedTimes
      .map((p: any) => moment(p))
      .filter((p: any) => p.isSameOrAfter(moment()));
    if (suggestTimes) {
      newProposedTimes = getSuggestedTimes(viewer.timezone, userTimezone, 12, 1);
    }
    newProposedTimes = newProposedTimes.concat(Array.from({ length: 3 - newProposedTimes.length }));
  }

  const initialValues = useMemo(
    () => ({
      dates: newProposedTimes,
      duration: Duration.parse(duration).toString(),
    }),
    [duration, newProposedTimes]
  );

  return (
    <Form
      // Final Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      // @ts-expect-error TS(2322) FIXME: Type '(values: any, props: any) => {} | undefined'... Remove this comment to see the full error message
      validate={validate}
      mutators={{ setFieldData, ...arrayMutators }}
      component={RescheduleDialog}
      // Only re-render if these parts of the form state change
      subscription={{
        submitting: true,
        values: true,
        dirty: true,
        pristine: true,
      }}
      // Dialog props
      viewer={viewer}
      open={open}
      user={user}
      userTimezone={userTimezone}
      creditRate={creditRate}
      unpaid={unpaid}
      isViewerExpert={isViewerExpert}
      rejectedTimes={rejectedTimes}
      proposedTimes={newProposedTimes}
      showDuration={showDuration}
      onCancel={onCancel}
      timesSuggested={suggestTimes}
      label={label}
      description={description}
    />
  );
}

export default connect(
  (state) => ({
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    viewer: state.viewer,
  }),
  {
    popup,
  }
)(Reschedule);
