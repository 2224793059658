import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { FormControlLabel, Checkbox as MuiCheckbox } from '@mui/material';
import MuiAutocomplete from '@mui/material/Autocomplete';
import MuiTextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { FC, useMemo } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { gql } from '@/__generated__/gql';
import { track } from '@/actions/tracking';
import { notify, popup } from '@/actions/ui';
import Button from '@/components/Button/Button';
import { promoPopup } from '@/components/SendMessageButton/promo';
import SettingsSection from '@/components/SettingsSection';
import { isAdvancedUser } from '@/core/group';
import { usePermissions } from '@/hooks/useAppContext';
import { RootState } from '@/store';

interface FormData {
  about: string;
  defaultAnonymousMessaging?: boolean;
  enforce2fa?: boolean;
  keywordNames?: string[];
}

interface PreferencesProps {
  groupId: string;
}

const GET_GROUP = gql(/* GraphQL */ `
  query preferencesGetGroup($id: String!) {
    group(id: $id) {
      internal
      about
      default_anonymous_messaging
      enforce_2fa
      profile_keywords_definition {
        id
        name
      }
    }
  }
`);

const GET_KEYWORD_COUNTS = gql(/* GraphQL */ `
  query preferencesGetKeywordCounts($ids: [String]!) {
    groupKeywordCounts(keyword_ids: $ids) {
      id
      name
      count
    }
  }
`);

const UPDATE_GROUP = gql(/* GraphQL */ `
  mutation preferencesUpdateGroup(
    $id: String!
    $about: String
    $defaultAnonymousMessaging: Boolean
    $enforce2fa: Boolean
    $keywords: [String]
  ) {
    updateGroup(
      id: $id
      about: $about
      default_anonymous_messaging: $defaultAnonymousMessaging
      enforce_2fa: $enforce2fa
      profile_keywords_definition: $keywords
    ) {
      id
    }
  }
`);

const connector = connect(
  (state: RootState) => ({
    viewer: state.viewer,
  }),
  {
    notify,
    popup,
    track,
  }
);

function setDifference<T>(setA: Set<T>, setB: Set<T>) {
  return new Set([...setA].filter((x) => !setB.has(x)));
}

const Preferences: FC<PreferencesProps & ConnectedProps<typeof connector>> = ({
  groupId,
  viewer,
  popup,
  track,
  notify,
}) => {
  const [canUpdateAdmin, canUpdateHigherTier] = usePermissions(
    [
      { service: 'group', action: 'update_admin_settings', resource: groupId },
      { service: 'group', action: 'update_higher_tier_settings', resource: groupId },
    ],
    [groupId]
  );

  const client = useApolloClient();

  const { data: groupData } = useQuery(GET_GROUP, { variables: { id: groupId } });
  const [updateGroup] = useMutation(UPDATE_GROUP);
  const group = groupData?.group;

  const keywords = useMemo(
    () => new Map((group?.profile_keywords_definition || []).map((k) => [k.id, k.name])),
    [group?.profile_keywords_definition]
  );

  const confirm = (contents: any): Promise<boolean> => {
    return new Promise((resolve) => {
      popup({
        title: 'Warning',
        contents,
        buttonAlignment: 'space-between',
        buttons: [
          { flat: true, label: 'Cancel', callback: () => resolve(false) },
          {
            label: 'Confirm',
            callback: () => resolve(true),
          },
        ],
      });
    });
  };

  const handleSubmit = async (values: FormData) => {
    // Warn if any keywords with counts are being removed
    if (values.keywordNames !== undefined) {
      const inverseKeywords = new Map(Array.from(keywords.entries()).map(([k, v]) => [v, k]));
      const newKeywordIds = new Set(
        values.keywordNames.map((n) => inverseKeywords.get(n)).filter((id) => id !== undefined)
      );
      const oldKeywordIds = new Set(keywords.keys());

      const removedIds = setDifference(oldKeywordIds, newKeywordIds);
      if (removedIds.size > 0) {
        const { data: countData } = await client.query({
          query: GET_KEYWORD_COUNTS,
          variables: { ids: Array.from(removedIds) },
        });

        const warnIds = (countData.groupKeywordCounts || []).filter((c) => c.count > 0);
        if (warnIds.length > 0) {
          const contents = (
            <div>
              Profiles marked with deleted team keyword(s) will have that annotation&nbsp;removed:
              {warnIds.map((c) => (
                <p key={c.id}>
                  {c.name}: {c.count} profile(s) affected
                </p>
              ))}
            </div>
          );
          const confirmed = await confirm(contents);
          if (!confirmed) {
            formik.setFieldValue('keywordNames', Array.from(keywords.values()));
            return;
          }
        }
      }
    }

    // Finally update the group
    try {
      await updateGroup({
        variables: {
          id: groupId,
          about: values.about,
          defaultAnonymousMessaging: values.defaultAnonymousMessaging,
          enforce2fa: values.enforce2fa,
          keywords: values.keywordNames,
        },
      });
      notify('Team settings updated.', 'success');
    } catch (err) {
      console.error(err);
      notify('Error when updating team settings.', 'error');
    }
  };

  const initialValues: FormData = {
    about: group?.about || '',
    defaultAnonymousMessaging: group?.default_anonymous_messaging || false,
    enforce2fa: group?.enforce_2fa || false,
    keywordNames: Array.from(keywords.values()),
  };

  const formik = useFormik({
    // KT: needed to work with useQuery
    enableReinitialize: true,
    initialValues,
    //validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const { isSubmitting } = formik;

  const showMessagingPromo = () => {
    promoPopup(popup, track);
  };

  if (!group) return null;

  /*
  For now, we allow super-admins to enable 2FA enforcement ONLY to the OnFrontiers group.
  To enable other teams and/or allow other team admins/owners to enable this feature we
  need to relax the following logic.  If team admins/owners wish to enable this feature
  on their team, `update_higher_tier_settings` permissions are appropriate. Note we can't
  use this for OnFrontiers group because members do not have this permission explicitly
  revoked and would be granted permission.

  TODO: When we choose to allow members outside of super-admins (OF owners/admins) to
  enforce 2FA or enable this feature for other teams, modify the below logic appropriately.
*/
  const displayEnforce2FA = !!(group.internal && canUpdateAdmin);
  const canSetDefaultAnonymousMessaging = isAdvancedUser(viewer);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <SettingsSection title="General">
          <MuiTextField
            name="about"
            multiline
            minRows={3}
            maxRows={10}
            label="About"
            placeholder="This will be displayed on your team sign up page"
            fullWidth
            value={formik.values.about}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.about && Boolean(formik.errors.about)}
            helperText={formik.touched.about && formik.errors.about}
          />
        </SettingsSection>
        <SettingsSection title="Messaging" box>
          <FormControlLabel
            control={
              <MuiCheckbox
                name="defaultAnonymousMessaging"
                checked={
                  canSetDefaultAnonymousMessaging ? formik.values.defaultAnonymousMessaging : false
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onClick={canSetDefaultAnonymousMessaging ? undefined : showMessagingPromo}
              />
            }
            label="Keep team members anonymous by default when messaging experts"
          />
        </SettingsSection>

        {displayEnforce2FA && (
          <SettingsSection title="Security" box>
            <FormControlLabel
              control={
                <MuiCheckbox
                  name="enforce2fa"
                  checked={formik.values.enforce2fa}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              }
              label="Require members to activate Two Factor Authentication"
            />
          </SettingsSection>
        )}

        {canUpdateHigherTier && (
          <SettingsSection
            title="Team Keywords"
            text="Define terms team members can use when listing expertise and organizing experts within your network"
            box
          >
            <MuiAutocomplete
              id="keywords"
              multiple
              freeSolo
              value={formik.values.keywordNames || []}
              options={[]}
              onChange={(_event: React.SyntheticEvent, value: string[]) =>
                formik.setFieldValue('keywordNames', value)
              }
              onBlur={formik.handleBlur}
              renderInput={(params) => (
                <MuiTextField {...params} placeholder="Click to add keywords" />
              )}
            />
          </SettingsSection>
        )}
        <Button type="submit" style={{ marginTop: 20 }} size="medium" disabled={isSubmitting}>
          Save
        </Button>
      </form>
    </div>
  );
};

export default connector(Preferences);
