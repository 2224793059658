import { useQuery } from '@apollo/client';
import { Checkbox, FormControlLabel } from '@mui/material';
import cx from 'classnames';
import moment from 'moment-timezone';
import { FC, useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import LabelledOutline from '@/components/LabelledOutline';
import { fileTypes } from '@/consultation';
import { fetchAttachmentContent } from '@/consultation/store';
import { TimeRange } from '@/core/time';

import RecordingPlayer from '../RecordingPlayer';
import s from './Metrics.module.scss';
import Timeline from './Timeline';
import Participant, {
  Call,
  PlivoParticipant,
  TwilioParticipant,
  ZoomParticipant,
} from './participant';
import { Conference, GET_CONSULTATION } from './queries';

const Legend = () => {
  return (
    <div className={s.legend}>
      <div className={s.legendItem}>
        <div className={cx(s.legendSquare, s.notConnectedLegend)} />
        Not connected
      </div>
      <div className={s.legendItem}>
        <div className={cx(s.legendSquare, s.connectedLegend)} />
        Connected
      </div>
      <div className={s.legendItem}>
        <div className={cx(s.legendSquare, s.warningLegend)} />
        Slow network
      </div>
      <div className={s.legendItem}>
        <div className={cx(s.legendDot, s.info)} />
        Info
      </div>
      <div className={s.legendItem}>
        <div className={cx(s.legendDot, s.warning)} />
        Warning
      </div>
      <div className={s.legendItem}>
        <div className={cx(s.legendDot, s.error)} />
        Error
      </div>
    </div>
  );
};

interface CallsProps {
  carrier: string;
  title: string;
  participants: Participant[];
  conferenceRange: TimeRange;
}

const Calls: FC<CallsProps> = ({ carrier, title, participants, conferenceRange }) => {
  return (
    <div>
      <div style={{ marginBottom: 10 }}>{title}</div>
      <Timeline carrier={carrier} range={conferenceRange} participants={participants} />
    </div>
  );
};

interface ChatLogProps {
  conference: any;
  fetchAttachmentContent: any;
}

const chatLogConnector = connect(undefined, {
  fetchAttachmentContent,
});

const ChatLogBare: FC<ChatLogProps & ConnectedProps<typeof chatLogConnector>> = ({
  conference,
  fetchAttachmentContent,
}) => {
  const [chatContent, setChatContent] = useState(undefined);

  useEffect(() => {
    (async function loadChatUrl() {
      if (!conference?.id) return;

      const chat = conference?.attachments?.find(
        (attachment: any) => attachment.file_type === fileTypes.chat
      );
      if (!chat) return;

      const content = await fetchAttachmentContent(chat);
      setChatContent(content);
    })();
  }, [conference, fetchAttachmentContent]);

  return (
    <div>
      <h3>Chat Log</h3>
      {chatContent ? (
        <LabelledOutline
          classes={{
            content: s.chatLogContent,
          }}
        >
          <div className={s.chatLog}>{chatContent}</div>
        </LabelledOutline>
      ) : (
        <p className={s.chatLogUnavailable}>Chat log not available.</p>
      )}
    </div>
  );
};

const ChatLog = chatLogConnector(ChatLogBare);

interface TimelinesProps {
  oneTimelineForEachConnection: boolean;
  participants: Participant[];
  participantsGrouped: Record<string, Participant[]>;
  consultation: any;
  conferenceRange: TimeRange;
}

const Timelines: FC<TimelinesProps> = ({
  oneTimelineForEachConnection,
  participants,
  consultation,
  participantsGrouped,
  conferenceRange,
}) => {
  return oneTimelineForEachConnection
    ? participants.map((p) => (
        <Calls
          carrier={consultation.conference.carrier}
          key={p.id}
          title={p.getCallOriginDescription()}
          participants={[p]}
          conferenceRange={conferenceRange}
        />
      ))
    : Object.keys(participantsGrouped).map((origin) => (
        <Calls
          carrier={consultation.conference.carrier}
          key={origin}
          title={origin}
          participants={participantsGrouped[origin]}
          conferenceRange={conferenceRange}
        />
      ));
};

function groupParticipants(participants: Participant[]) {
  const groupedParticipants: Record<string, Participant[]> = {};

  participants.forEach((p) => {
    const origin = p.getCallOriginDescription();
    if (!groupedParticipants[origin]) groupedParticipants[origin] = [];
    groupedParticipants[origin].push(p);
  });

  return groupedParticipants;
}

function getParticipants(conference: Conference): Participant[] {
  const parts = (conference.participants || []).filter((p) => !!p);

  switch (conference.carrier) {
    case 'twilio':
      return parts.map((p) => p && new TwilioParticipant(p));

    case 'plivo':
      return parts.map((p) => new PlivoParticipant(p));

    case 'zoom': {
      const participants: Record<string, any>[] = [];
      // for context: on plivo/twilio each join creates a new carrier call id,
      // but for zoom it keeps the instance id, it only changes when all
      // participants leave and then join again.

      // split ranges from same instance into single participants
      parts.forEach((p) => {
        const call = p.call as Call | undefined | null;
        if (!call) return;

        participants.push(
          ...call.ranges.map((r, idx) => ({
            ...p,
            id: `${p.id}_${idx}`,

            call: {
              ...call,
              ...r,
            },
          }))
        );
      });

      return participants.filter(Boolean).map((p) => new ZoomParticipant(p));
    }
    default:
      throw new Error('unsupported carrier');
  }
}

interface MetricsProps {
  consultationId: string;
}

const Metrics: FC<MetricsProps> = ({ consultationId }) => {
  const { data } = useQuery(GET_CONSULTATION, {
    variables: {
      id: consultationId,
    },
  });

  const [state, setState] = useState({
    oneTimelineForEachConnection: false,
    hideConnectionsWithZeroDuration: false,
  });

  const consultation = data?.consultation;
  const conference = consultation?.conference;
  if (!conference) {
    return null;
  }

  const participants = getParticipants(conference);

  let firstCallStart: any;
  let lastCallEnd: any;
  participants
    .filter((p: any) => p.hasCall())
    .forEach((p: any) => {
      const start = new Date(p.callStart());
      const end = p.callEnd() ? new Date(p.callEnd()) : new Date();

      if (!firstCallStart || start < firstCallStart) firstCallStart = start;
      if (!lastCallEnd || end > lastCallEnd) lastCallEnd = end;
    });

  const conferenceRange = new TimeRange(moment(firstCallStart), moment(lastCallEnd));

  const { oneTimelineForEachConnection, hideConnectionsWithZeroDuration } = state;

  let clientParticipants = participants.filter(
    (p: any) => p.identifier === consultation.requester_identifier || p.getGrouper() === 'requester'
  );
  let expertParticipants = participants.filter(
    (p: any) => p.identifier === consultation.expert_identifier || p.getGrouper() === 'expert'
  );
  let unknownParticipants = participants.filter((p: any) => p.getGrouper() === 'unknown');
  if (hideConnectionsWithZeroDuration) {
    clientParticipants = clientParticipants.filter((p: any) => p.callDuration());
    expertParticipants = expertParticipants.filter((p: any) => p.callDuration());
    unknownParticipants = unknownParticipants.filter((p: any) => p.callDuration());
  }

  const clientParticipantsGrouped = groupParticipants(clientParticipants);
  const expertParticipantsGrouped = groupParticipants(expertParticipants);
  const unknownParticipantsGrouped = groupParticipants(unknownParticipants);
  const clientData = Object.keys(clientParticipants).length > 0;
  const expertData = Object.keys(expertParticipants).length > 0;
  const unknownData = Object.keys(unknownParticipants).length > 0;
  const noData =
    Object.keys(clientParticipants).length === 0 &&
    Object.keys(expertParticipants).length === 0 &&
    Object.keys(unknownParticipants).length === 0;

  const hasEvents = participants.some((p: any) => p.events());

  const timelinesProps = {
    oneTimelineForEachConnection,
    consultation,
    conferenceRange,
  };

  return (
    <div>
      {hasEvents && <Legend />}
      <div className={s.checks}>
        <FormControlLabel
          classes={{ root: s.check }}
          control={
            <Checkbox
              checked={oneTimelineForEachConnection}
              onChange={() =>
                setState((prev) => ({
                  ...prev,
                  oneTimelineForEachConnection: !prev.oneTimelineForEachConnection,
                }))
              }
            />
          }
          label="Show one timeline for each connection"
        />
        <FormControlLabel
          classes={{ root: s.check }}
          control={
            <Checkbox
              checked={hideConnectionsWithZeroDuration}
              onChange={() =>
                setState((prev) => ({
                  ...prev,
                  hideConnectionsWithZeroDuration: !prev.hideConnectionsWithZeroDuration,
                }))
              }
            />
          }
          label="Hide connections with zero duration"
        />
      </div>
      {clientData && <h3>Client</h3>}
      <Timelines
        {...timelinesProps}
        participants={clientParticipants}
        participantsGrouped={clientParticipantsGrouped}
      />

      {expertData && <h3>Expert</h3>}
      <Timelines
        {...timelinesProps}
        participants={expertParticipants}
        participantsGrouped={expertParticipantsGrouped}
      />

      {unknownData && <h3>Other</h3>}
      <Timelines
        {...timelinesProps}
        participants={unknownParticipants}
        participantsGrouped={unknownParticipantsGrouped}
      />

      <RecordingPlayer conference={conference} enabled />

      <ChatLog conference={conference} />

      {noData && <h3>No metrics data available.</h3>}
    </div>
  );
};

export default Metrics;
