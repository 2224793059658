import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import ContractsDrawer from '@/assignment/components/ContractsDrawer';
import ProjectDetailsDrawer from '@/assignment/components/ProjectDetailsDrawer';
import KnowledgeDetailsDrawer from '@/knowledge/components/KnowledgeDetailsDrawer';
import KnowledgeListDrawer from '@/knowledge/components/KnowledgeListDrawer';
import ExperienceDetailsDrawer from '@/profilev2/components/ExperienceDetailsDrawer';
import ExperiencesByColleagueDrawer from '@/profilev2/components/ExperiencesByColleagueDrawer';
import JobsDrawer from '@/profilev2/components/JobsDrawer';
import LocationDetailsDrawer from '@/profilev2/components/LocationDetailsDrawer';
import LocationListDrawer from '@/profilev2/components/LocationListDrawer';
import ProfileRedirect from '@/routes/ProfileRedirect';
import { ProfileFetcher } from '@/searchv2/components/SearchDrawers';
import Search from '@/searchv2/pages';
import { isTrue } from '@/utils/validation';

import APP_ROUTES from './APP_ROUTES';
import ProtectedRoute from './ProtectedRoute';
import getStorybookRoute from './StorybookRoute';
import TrackPathname from './TrackPathname';
import Admin from './admin/Admin';

const Onboarding = lazy(() => import('@/onboardingv2/pages'));
const CollectionById = lazy(() => import('@/collection/pages/CollectionById'));
const Profile = lazy(() => import('@/profilev2/pages'));
const Collections = lazy(() => import('@/collection/pages'));
const MyCollections = lazy(() => import('@/collection/pages/MyCollections'));
//const Login = lazy(() => import('@/auth/pages/HarnessLoginPage'));
const Dashboard = lazy(() => import('@/dashboardv2/pages'));

const isAdminEnabled = isTrue(import.meta.env.VITE_OF_ENABLE_ADMIN);

const profileChildren: RouteObject[] = [
  { path: 'jobs', element: <JobsDrawer /> },
  { path: 'jobs/:jobId', element: <ExperienceDetailsDrawer /> },
  { path: 'contracts/projects', element: <ContractsDrawer type="Project" /> },
  {
    path: 'contracts/projects/:projectId',
    element: <ProjectDetailsDrawer />,
  },
  {
    path: 'contracts/proposals',
    element: <ContractsDrawer type="Proposal" />,
  },
  {
    path: 'contracts/proposals/:projectId',
    element: <ProjectDetailsDrawer />,
  },
  {
    path: 'knowledge-types/:knowledgeType',
    element: <KnowledgeListDrawer />,
  },
  { path: 'knowledge', element: <KnowledgeDetailsDrawer /> },
  { path: 'locations', element: <LocationListDrawer /> },
  { path: 'location', element: <LocationDetailsDrawer /> },
  {
    path: 'colleagues/:colleagueId',
    element: <ExperiencesByColleagueDrawer />,
  },
];

const profileFetcherChildren: RouteObject[] = [
  { path: 'jobs', element: <JobsDrawer /> },
  {
    path: 'jobs/:jobId',
    element: <ExperienceDetailsDrawer disableEditing />,
  },
  {
    path: 'contracts/projects',
    element: <ContractsDrawer disableEditing type="Project" />,
  },
  {
    path: 'contracts/projects/:projectId',
    element: <ProjectDetailsDrawer disableEditing />,
  },
  {
    path: 'contracts/proposals',
    element: <ContractsDrawer disableEditing type="Proposal" />,
  },
  {
    path: 'contracts/proposals/:projectId',
    element: <ProjectDetailsDrawer disableEditing />,
  },
  {
    path: 'knowledge-types/:knowledgeType',
    element: <KnowledgeListDrawer />,
  },
  {
    path: 'knowledge',
    element: <KnowledgeDetailsDrawer disableEditing />,
  },
  { path: 'locations', element: <LocationListDrawer /> },
  {
    path: 'location',
    element: <LocationDetailsDrawer disableEditing />,
  },
  {
    path: 'colleagues/:colleagueId',
    element: <ExperiencesByColleagueDrawer />,
  },
];

const harnessRoutes: RouteObject[] = [
  {
    element: <TrackPathname />,
    children: [
      // {
      //   path: APP_ROUTES.callback,
      //   element: <CallbackRoute />,
      // },
      {
        path: APP_ROUTES.my_collections,
        element: <ProtectedRoute component={MyCollections} />,
      },
      {
        path: APP_ROUTES.collections,
        element: <ProtectedRoute component={Collections} />,
      },
      {
        path: APP_ROUTES.collectionDetails,
        element: <ProtectedRoute component={CollectionById} />,
      },
      // {
      //   path: APP_ROUTES.logout,
      //   element: <div>Log Out</div>,
      // },
      {
        path: '/profilev2',
        element: <ProtectedRoute component={Profile} />,
        children: profileChildren,
      },
      {
        path: '/profilev2/:id',
        element: <ProtectedRoute component={Profile} />,
        children: profileChildren,
      },
      {
        path: APP_ROUTES.search,
        element: <ProtectedRoute component={Search} />,
        children: [
          {
            path: 'profile/:id',
            element: <ProfileFetcher />,
            children: profileFetcherChildren,
          },
        ],
      },
      {
        path: 'searchv2/saved/:savedSearchId',
        element: <ProtectedRoute component={Search} />,
        children: [
          {
            path: 'profile/:id',
            element: <ProfileFetcher />,
            children: profileFetcherChildren,
          },
        ],
      },
      // {
      //   path: APP_ROUTES.login,
      //   element: <Login />,
      // },
      {
        path: APP_ROUTES.onboarding,
        element: <ProtectedRoute component={Onboarding} redirectTo={APP_ROUTES.profile()} />,
      },
      {
        path: APP_ROUTES.dashboard,
        element: <ProtectedRoute component={Dashboard} redirectTo={APP_ROUTES.profile()} />,
        children: profileChildren,
      },
      {
        path: APP_ROUTES.admin,
        element: (
          <ProtectedRoute
            component={Admin}
            disabled={!isAdminEnabled}
            redirectTo={APP_ROUTES.profile()}
          />
        ),
      },
      getStorybookRoute(),
      {
        path: '/profilev2/summary',
        element: <Navigate to="/profilev2" replace />,
      },
      {
        path: '/profilev2/summary/:id',
        element: <ProfileRedirect />,
      },
      {
        path: '/profilev2/resume',
        element: <Navigate to="/profile" replace />,
      },
      {
        path: '/profilev2/resume/:id',
        element: <ProfileRedirect />,
      },
    ],
  },
];

export default harnessRoutes;
