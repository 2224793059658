import { useSuspenseQuery } from '@apollo/client';
import { MetaData, UserOnlineState } from '@sendbird/chat';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { FC, memo, useState } from 'react';
import MediaQuery from 'react-responsive';

import { gql } from '@/__generated__';
import { useAuth } from '@/auth';
import Button from '@/components/Button';
import MaterialIcon from '@/components/Icon/MaterialIcon';
import Link from '@/components/Link';
import Picture from '@/components/Picture/Picture';
import RequestConsultation from '@/components/RequestConsultation/RequestConsultation';
import Tidbit from '@/components/Tidbit/Tidbit';
import { canRequestConsultation } from '@/consultation';
import { SCREEN_MD } from '@/theme/screens';

import { isAnonymousUser } from '../sendbird';
import { findOtherMember } from '../utils';
import s from './ChatHeader.module.scss';
import OnlineStatus from './OnlineStatus';

interface ChatHeaderProps {
  channel: GroupChannel;
  metadata: MetaData;
  initialAnonymous?: boolean;
  isMobileVersion?: boolean;
  onBackToChannels?: () => void;
}

const GET_USER = gql(/* GraphQL */ `
  query getChatHeaderUser($id: String!) {
    user(id: $id) {
      id
      profile {
        name
        first_name
        last_name
        picture_url
        html_url
        city
        country
        expert_state
        available_self_service
        can_request_consultation
        credit_rate
        timezone
        languages {
          code
          fluency
        }
      }
    }
  }
`);

const ChatHeader: FC<ChatHeaderProps> = memo(
  ({ channel, metadata, isMobileVersion = false, onBackToChannels }) => {
    const { viewer } = useAuth();

    const [requestConsultationOpen, setRequestConsultationOpen] = useState(false);

    if (!viewer.id) {
      throw new Error('Chat header requires logged in user');
    }
    const otherMember = findOtherMember(channel.members, viewer.id);
    const isAnonymous = isAnonymousUser(metadata, otherMember.userId);

    const { data } = useSuspenseQuery(GET_USER, {
      variables: {
        id: otherMember.userId,
      },
      // KT: note that this will still return cached value if skip is true
      skip: isAnonymous,
    });

    const handleRequestConsultation = () => setRequestConsultationOpen(true);
    const closeRequestConsultation = () => setRequestConsultationOpen(false);

    const user = isAnonymous ? null : data?.user;
    const userId = user?.id;
    const profile = user?.profile;
    const showTidbit = profile?.city || profile?.country || profile?.languages?.length;

    if (!isAnonymous && !profile) throw new Error('User has no profile');

    return (
      <div className={s.header}>
        {isMobileVersion && (
          <div className={s.backToChannels}>
            <MaterialIcon icon="arrow_back" onClick={onBackToChannels} />
          </div>
        )}
        <div className={s.pictureContainer}>
          <Picture user={profile} size={isMobileVersion ? 40 : 50} />

          {otherMember.connectionStatus === UserOnlineState.ONLINE && (
            <OnlineStatus style={isMobileVersion ? { bottom: 0, right: -2 } : undefined} />
          )}
        </div>
        <div className={s.messagesTitleContainer}>
          <div className={s.messagesTitle}>
            {profile?.name ? (
              <Link to={profile.html_url}>{profile.name}</Link>
            ) : (
              'Confidential Client'
            )}
          </div>
          {showTidbit && (
            <div className={s.messagesSubTitle}>
              <MediaQuery maxWidth={SCREEN_MD}>
                {(isMediumScreen: any) => (
                  <Tidbit
                    inline={!isMediumScreen}
                    city={profile.city || undefined}
                    country={profile.country || undefined}
                    languages={profile.languages || undefined}
                  />
                )}
              </MediaQuery>
            </div>
          )}
        </div>
        {canRequestConsultation(profile) && (
          <div className={s.requestConsultation}>
            <Button
              size={isMobileVersion ? 'small' : 'normal'}
              onClick={handleRequestConsultation}
              style={{ fontSize: 14 }}
            >
              Request Call
            </Button>
          </div>
        )}
        {requestConsultationOpen && (
          <RequestConsultation
            open={requestConsultationOpen}
            onClose={closeRequestConsultation}
            expertId={userId}
            profile={profile}
          />
        )}
      </div>
    );
  }
);
ChatHeader.displayName = 'ChatHeader';

export default ChatHeader;
