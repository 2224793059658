import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm } from 'redux-form';

import { fetchAllGroups } from '@/actions/group';
import { updateProfileKeywords } from '@/actions/internalNetwork';
import Divider from '@/components/Divider/Divider';
import Form from '@/components/Form';
import { Select } from '@/components/FormAdapters/FormAdapters';
import KeywordInput from '@/components/KeywordInput';
import { useApp } from '@/hooks/useAppContext';
import { updateProfile } from '@/profile/store';
import { darkGreen } from '@/theme/colors';

import AddButton from './buttons/AddButton';
import RemoveButton from './buttons/RemoveButton';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    marginBottom: 15,
  },
  field: {
    flex: 1,
  },
  remove: {
    marginRight: 10,
  },
  add: {
    paddingTop: 10,
    color: darkGreen,
    fontWeight: 'bold',
    cursor: 'pointer',
    display: 'inline-block',
  },
}));

const GroupField = ({ field, options, selected, onRemoveGroup, change }: any) => {
  const s = useStyles();

  return (
    <div className={s.container} key={field}>
      <div className={s.remove}>
        <RemoveButton onClick={onRemoveGroup} />
      </div>
      <div className={s.field}>
        <Field
          name={`${field}.group.id`}
          // @ts-expect-error
          component={Select}
          label="Select Team"
          margin="none"
          options={options}
          disabled={!options.length}
          onChange={(_, value) => {
            change(field, {
              group: { id: value },
              keywords: [],
            });
          }}
        />

        <Field
          component={KeywordInput}
          openOnFocus
          primary
          disabled={!selected}
          options={selected?.keywords || []}
          getOptionLabel={(o: any) => o.name}
          name={`${field}.keywords`}
          placeholder="Team-Specific Keywords"
          style={{ marginBottom: 24 }}
        />
      </div>
    </div>
  );
};

const GroupsKeyword = (props: any) => {
  const { fields, groups = [], options, array } = props;

  const ids = groups.map((g: any) => g.group?.id);

  // check if all available group fields are selected
  const allFilled = ids.length === ids.filter(Boolean).length;
  const allSelected = ids.length === options.length;

  const handleAddKeyword = (field: any, keywords: any, value: any) => {
    const keyword = keywords.find((k: any) => k.id === value.id || k.name === value.name);

    if (keyword) array.push(`${field}.keywords`, keyword);
  };

  const handleRemoveKeyword = (field: any, index: any) => {
    array.remove(`${field}.keywords`, index);
  };

  const handleAddGroup = () => {
    fields.push({ keywords: [] });
  };

  const addButton = !allSelected && <AddButton label="Team" onClick={handleAddGroup} />;

  return (
    <>
      {fields?.map((field: any, idx: any) => {
        const value = ids[idx];
        const selected = options.find((op: any) => op.value === value);

        return (
          <GroupField
            {...props}
            field={field}
            options={options.filter((op: any) => op.value === value || !ids.includes(op.value))}
            idx={idx}
            key={field}
            ids={ids}
            selected={selected}
            onAddKeyword={handleAddKeyword}
            onRemoveKeyword={handleRemoveKeyword}
            onAddGroup={handleAddGroup}
            onRemoveGroup={() => fields.remove(idx)}
          />
        );
      })}

      {allFilled && !allSelected && addButton}
    </>
  );
};

export function KeywordsFields({
  showGeneralKeywords = true,
  showGroupKeywords = true,
  component: Container = Form,
  groups,
  formValues,
  handleSubmit,
  handleReset,
  array,
  change,
  ...other
}: any) {
  const options = useMemo(
    () =>
      groups?.edges
        // @ts-expect-error TS(7031): Binding element 'g' implicitly has an 'any' type.
        ?.filter(({ node: g }) => g.profile_keywords_definition?.length)
        // @ts-expect-error TS(7031): Binding element 'g' implicitly has an 'any' type.
        .map(({ node: g }) => ({
          label: g.name,
          value: g.id,
          keywords: g.profile_keywords_definition,
        })),
    [groups]
  );

  return (
    <Container {...other} onSubmit={handleSubmit} onReset={handleReset}>
      {showGroupKeywords && (
        <FieldArray
          name="group_keywords"
          groups={formValues?.group_keywords}
          component={GroupsKeyword}
          options={options}
          array={array}
          change={change}
        />
      )}

      {showGroupKeywords && showGeneralKeywords && <Divider />}

      {showGeneralKeywords && (
        <Field
          component={KeywordInput}
          freeSolo
          getOptionLabel={(o: any) => o}
          autoSelect
          options={[]}
          id="keywords"
          name="keywords"
          label="Other Keywords"
        />
      )}
    </Container>
  );
}

function KeywordsForm({
  profileId,
  profile,
  self,
  onSubmit,
  viewer,
  fetchAllGroups,
  updateProfile,
  updateProfileKeywords,
  reset,
  onReset,
  handleSubmit: handleSubmitProp,
  ...rest
}: any) {
  const { permission: permissions } = useApp();

  useEffect(() => {
    fetchAllGroups({
      keywordsConfig: true,
    });
  }, [fetchAllGroups]);

  const canEditGroupKeywords = useMemo(
    () => permissions.allowed('group_member', 'update_group_keywords', profileId),
    [permissions, profileId]
  );

  const canEditProfileKeywords = useMemo(() => self || viewer.admin, [self, viewer.admin]);

  const handleSubmit = (values: any) => {
    if (canEditProfileKeywords) {
      updateProfile({ id: profileId, ...values });
    }

    const reduceKeywords = (groupKeywords: any) =>
      (groupKeywords || []).reduce((ks: any, g: any) => [...ks, ...g.keywords], []);

    const keywords = reduceKeywords(profile.group_keywords);
    const valuesKeywords = reduceKeywords(values.group_keywords);

    const toAdd = valuesKeywords
      .map((k: any) => k.id)
      .filter((id: any) => !keywords.some((k: any) => k.id === id));

    const toDelete = keywords
      .map((k: any) => k.id)
      .filter((id: any) => !valuesKeywords.some((k: any) => k.id === id));

    if (toAdd.length > 0 || toDelete.length > 0) {
      updateProfileKeywords(profile.id, toAdd, toDelete);
    }

    if (onSubmit) {
      onSubmit(values);
    }
  };

  const handleReset = () => {
    reset();

    if (onReset) {
      onReset();
    }
  };

  return (
    <KeywordsFields
      {...rest}
      showGroupKeywords={canEditGroupKeywords}
      showGeneralKeywords={canEditProfileKeywords}
      handleSubmit={handleSubmitProp(handleSubmit)}
      handleReset={handleReset}
    />
  );
}

// @ts-expect-error TS(2630): Cannot assign to 'KeywordsForm' because it is a fu... Remove this comment to see the full error message
KeywordsForm = reduxForm({
  form: 'expertKeywords',
  enableReinitialize: true,
})(KeywordsForm);

// @ts-expect-error TS(2630): Cannot assign to 'KeywordsForm' because it is a fu... Remove this comment to see the full error message
KeywordsForm = connect(
  (state, ownProps) => {
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    return {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      self: state.viewer.id === ownProps.userId,
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      viewer: state.viewer,
      profile,
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      groups: state.groups.all || [],
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      formValues: state.form.expertKeywords?.values,
      initialValues: {
        group_keywords: profile.group_keywords?.length
          ? profile.group_keywords
          : [{ keywords: [] }],
        keywords: profile.keywords,
      },
    };
  },
  {
    updateProfile,
    updateProfileKeywords,
    fetchAllGroups,
  }
)(KeywordsForm);

export default KeywordsForm;
