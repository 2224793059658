import moment from 'moment-timezone';
import { redirect } from 'react-router-dom';

import { track } from '@/actions/tracking';
import { notify } from '@/actions/ui';
import InaccessibleArchived from '@/components/InaccessibleArchived';
import WrapperComponent from '@/components/WrapperComponent';
import {
  Trigger,
  engagementTypes,
  fetchConsultation,
  updateConsultation,
} from '@/consultation/store';
import { Viewer } from '@/core/viewer';
import { LegacyRoute } from '@/routes/routesMiddleware';
import { AppStore } from '@/store';
import { formatDate, formatDateTime } from '@/utils';

import Consultation from '../components/Consultation';

const route: LegacyRoute = {
  path: '/consultation/:id/:section?',
  element: <WrapperComponent />,
  async action({ store, params, query, permission }) {
    const { section, id } = params;

    if (section && !['request', 'transcript', 'feedback', 'metrics'].includes(section))
      return redirect('/404');

    const { viewer } = store.getState();

    const consultation = await store.dispatch(fetchConsultation(id));

    if (!consultation) return redirect('/404');

    const { permissions } = consultation;

    if (consultation.archived && (!permissions || !permissions.includes('view_archived'))) {
      store.dispatch(track('promo.show.consultation.archived'));
      document.title = 'Consultation Details';
      return (
        <InaccessibleArchived
          selectedTab="consultations"
          entity="consultation"
          entities="consultations"
          trackingCode="promo.chat.consultation.archived"
        />
      );
    }

    const requesterId = consultation.requester?.id;
    const billingAccountId = consultation.billing_account_id;
    if (requesterId) {
      await permission.allowedBatch([
        {
          service: 'messaging',
          action: 'start_non_anonymous_chat',
          resource: requesterId,
        },
        {
          service: 'messaging',
          action: 'start_anonymous_chat',
          resource: requesterId,
        },
        ...(billingAccountId
          ? [
              {
                service: 'billing',
                action: 'create_transaction',
                resource: billingAccountId,
              },
            ]
          : []),
      ]);
    }

    if (section === 'transcript') {
      if (consultation.transcription) {
        store.dispatch(track('consultation.transcription.view', consultation.id, undefined, true));
      } else {
        return redirect('/404');
      }
    }

    if (section === 'metrics') {
      if (
        !viewer.admin ||
        !consultation.conference ||
        !['confirmed', 'in_progress', 'finalizing', 'completed', 'incomplete'].includes(
          consultation.state
        )
      ) {
        return redirect('/404');
      }
    }

    const timeChosen = query.confirm_time;
    if (timeChosen && timeChosen !== 'none' && consultation.engagement_type !== 'opportunity') {
      await confirmTime(store, consultation, timeChosen);
    }

    const {
      expert,
      requester,
      external,
      state,
      client_review: clientReview,
      expert_review: expertReview,
      ended_at: endedAt,
    } = consultation;

    if (expertReview) {
      await permission.allowedBatch([
        {
          service: 'consultation_review',
          action: 'update',
          resource: expertReview.id,
        },
      ]);
    }

    if (clientReview) {
      await permission.allowedBatch([
        {
          service: 'consultation_review',
          action: 'update',
          resource: clientReview.id,
        },
      ]);
    }

    const openSuggestTime = timeChosen === 'none' && userAbleToSelectTime(viewer, consultation);
    const isViewerExpert = expert && viewer.id === expert.id;
    const isViewerClient = requester && viewer.id === requester.id;
    const hasCompletedComplianceTraining = expert && !!expert.compliance_completed_at;
    const openCompleteTraining = query.token && isViewerExpert && !hasCompletedComplianceTraining;

    const dismissed = store.getState().consultationReviews.dismissedPermanently;
    const isCompleted = state === 'completed';
    const hasReview = !!(isViewerExpert
      ? clientReview && clientReview.id
      : expertReview && expertReview.id);
    const withinOneMonth = endedAt && moment(endedAt).add(30, 'd').isAfter(moment());
    const shouldReview =
      !external &&
      isCompleted &&
      !hasReview &&
      withinOneMonth &&
      (isViewerClient || isViewerExpert) &&
      !dismissed.some((d: any) => d === consultation.id);
    const details =
      consultation.engagement_type === engagementTypes.writtenResponse
        ? 'response_details'
        : 'call_details';

    document.title = `Consultation with ${expert ? expert.name : 'Expert'}`;

    return (
      <Consultation
        store={store}
        consultationId={consultation.id}
        // @ts-ignore
        section={section || details}
        openSuggestTime={openSuggestTime}
        openCompleteTraining={openCompleteTraining}
        openReviewCall={query.review_call === 'true' || shouldReview}
        openRequestTranscript={query.request_transcript === 'true'}
        // @ts-ignore
        initialMatchExperienceRating={query.match_experience && parseInt(query.match_experience)}
        // @ts-ignore
        initialUsefulnessRating={query.usefulness && parseInt(query.usefulness)}
        //joinCall={query.join_call === 'true'}
        timeChosen={timeChosen}
      />
    );
  },
};

async function confirmTime(store: AppStore, consultation: any, time: any) {
  const { viewer } = store.getState();
  const { proposed_times: proposedTimes = [] } = consultation;
  const selectedTime = proposedTimes.find((x: any) => x === time);

  // validate the time was selected from proposed
  if (!selectedTime) return redirect('/404');

  // validate user ability to select a time
  if (!userAbleToSelectTime(viewer, consultation)) return redirect('/404');

  try {
    await store.dispatch(
      updateConsultation({
        id: consultation.id,
        starts_at: selectedTime,
        state: 'confirmed',
        trigger: Trigger.emailLink,
      })
    );
    const successMessage =
      consultation.engagement_type === engagementTypes.writtenResponse
        ? `You accepted proposed deadline of ${formatDate(selectedTime, viewer.timezone || undefined)}.`
        : `You accepted suggested call time of ${formatDateTime(selectedTime, viewer.timezone || undefined)}.`;

    await store.dispatch(notify(successMessage, 'success'));
  } catch (e) {
    console.error(e);
    await store.dispatch(notify('An error occurred when accepting the consultation.', 'error'));
  }
}

function userAbleToSelectTime(viewer: Viewer, consultation: any) {
  if (
    consultation.state === 'negotiating_expert_time' &&
    consultation.expert &&
    consultation.expert.id === viewer.id &&
    !!consultation.expert.compliance_completed_at
  )
    return true;
  if (
    consultation.state === 'negotiating_client_time' &&
    consultation.requester &&
    consultation.requester.id === viewer.id
  )
    return true;
  return false;
}

export default route;
