import { redirect } from 'react-router-dom';

import { fetchBillingAccount, fetchPrices } from '@/actions/billing';
import { fetchCountries } from '@/actions/country';
import { notify } from '@/actions/ui';
import ChangePasswordForm from '@/auth/components/ChangePasswordForm';
import BillingSettings from '@/components/BillingSettings';
import ConsultationsStarting from '@/components/ConsultationsStarting';
import CreditPurchaseForm from '@/components/CreditPurchaseForm';
import CreditSettings from '@/components/CreditSettings';
import LayoutPage from '@/components/Layout/LayoutPage';
import Settings from '@/components/Settings';
import WrapperComponent from '@/components/WrapperComponent';
import config from '@/config';
import { formatAccountType } from '@/core/billing';
import SecurityForm from '@/profile/components/forms/Security';
import { fetchProfile } from '@/profile/store';
import { fetchUser } from '@/store/user';

import Communication from './Communication';
import Emails from './Emails';
import Location from './Location';
import Privacy from './Privacy';
import Rate from './Rate';

export default {
  path: '/settings/:section?/:subsection?',
  element: <WrapperComponent />,
  async action({ store, params, query }: any) {
    const path = !params.section
      ? null
      : !params.subsection
        ? params.section
        : `${params.section}/${params.subsection}`;

    const { error } = query;

    const { viewer } = store.getState();
    const accountId = viewer.billing_account_id;

    await store.dispatch(
      fetchUser(viewer.username, {
        addresses: true,
        otpAuthEnabled: true,
        expertRequestCandidates: true,
      })
    );
    await store.dispatch(fetchCountries());
    await store.dispatch(fetchProfile(viewer.username, { force: true }));
    await store.dispatch(fetchPrices(accountId));
    if (accountId) {
      await store.dispatch(fetchBillingAccount(accountId));
    }
    if (error) {
      let errorMessage;
      switch (error) {
        case 'account_not_found':
          errorMessage = 'Payout account not found. Please contact support to resolve this issue.';
          break;
        case 'country_unsupported':
          errorMessage =
            'Your country is not supported for automated payouts. Please contact support.';
          break;
        default:
          errorMessage = 'An error occurred. Please try again later.';
          break;
      }
      store.dispatch(notify(errorMessage, 'error'));
    }

    const { billing } = store.getState();

    const account = accountId
      ? billing.accounts[accountId]
      : {
          account_type: 'basic',
          entity_type: 'individual',
        };

    const requesterItems = [
      {
        title: 'Credits',
        path: 'credits',
        component: CreditSettings,
        componentProps: {
          account,
          purchasePath: `/settings/credits/purchase`,
          // memberNotOwner: !memberOwner,
          accountType: formatAccountType(account.account_type),
          seatCount: account.seat_count,
        },
      },
      {
        title: 'Purchase Credits',
        path: 'credits/purchase',
        component: CreditPurchaseForm,
        componentProps: {
          account,
          purchaseDonePath: `/settings/credits`,
        },
      },
      {
        title: 'Billing',
        path: 'billing',
        url: true,
        component: BillingSettings,
        componentProps: {
          account,
        },
      },
    ];

    const expertItems = [
      {
        title: 'Rate & Availability',
        path: 'rate',
        component: Rate,
      },
      {
        title: 'Privacy',
        path: 'privacy',
        component: Privacy,
      },
    ];
    if (config.enablePayoutDashboard) {
      expertItems.push({
        title: 'Payout Dashboard',
        path: 'payout',
        url: true,
        // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(({ profi... Remove this comment to see the full error message
        component: 'div',
      });
    }

    const nav = [
      {
        title: 'Settings',
        items: [
          {
            title: 'Emails',
            path: 'emails',
            component: Emails,
          },
          {
            title: 'Location',
            path: 'location',
            component: Location,
          },
          {
            title: 'Communication',
            path: 'communication',
            component: Communication,
          },
          {
            title: 'Change Password',
            path: 'password',
            component: ChangePasswordForm,
            id: 'settingChangePassword',
          },
          {
            title: 'Security',
            path: 'security',
            component: SecurityForm,
          },
        ],
      },
      {
        title: 'Requester',
        items: requesterItems,
      },
      {
        title: 'Expert',
        items: expertItems,
      },
    ];

    const sections = nav.map((section) => ({
      ...section,
      items: section.items.map((nav) => ({
        ...nav,
        componentProps: {
          // @ts-expect-error TS(2339) FIXME: Property 'componentProps' does not exist on type '... Remove this comment to see the full error message
          ...nav.componentProps,
          userId: viewer.id,
          profileId: viewer.profile.id,
        },
      })),
    }));

    let section;
    if (!path) {
      section = sections[0].items[0];
    } else {
      for (let i = 0; i < sections.length; i++) {
        const part = sections[i];
        section = part.items.find((e) => e.path === path);
        if (section) {
          break;
        }
      }
    }

    if (!section) {
      return redirect('/404');
    }

    document.title = `${section.title} Settings`;
    return (
      <LayoutPage showNav selected="settings">
        <ConsultationsStarting />
        {<Settings pathPrefix="/settings" nav={sections} {...section} />}
      </LayoutPage>
    );
  },
};
