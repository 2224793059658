import { useQuery } from '@apollo/client';

import { gql } from '@/__generated__/gql';

import CustomSelect from '../Select';

const FETCH_ALL_GROUPS = gql(/* GraphQL */ `
  query getGroups(
    $internal: Boolean
    $memberOnly: Boolean
    $cursor: String
    $pageSize: Int!
    $sharingExperts: Boolean
    $memberLimit: Int
    $name: String
    $includeStats: Boolean = false
    $includeBillingAccount: Boolean = false
    $includeMembers: Boolean = false
    $includeInternalNetwork: Boolean = false
    $includeKeywordsConfig: Boolean = false
  ) {
    groups(
      internal: $internal
      member_only: $memberOnly
      first: $pageSize
      after: $cursor
      sharing_experts: $sharingExperts
      name: $name
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          html_url
          slug
          name
          internal
          about
          stats @include(if: $includeStats) {
            expert_request_count
            consultation_count
          }
          billing_account @include(if: $includeBillingAccount) {
            id
            type
            state
            credit_balance {
              cents
              currency
            }
          }
          members(first: $memberLimit) @include(if: $includeMembers) {
            edges {
              node {
                id
                role
                email
                user {
                  id
                  first_name
                  last_name
                  name
                  html_url
                  picture_url
                }
              }
            }
            pageInfo {
              total
            }
          }
          internal_network @include(if: $includeInternalNetwork) {
            id
            name
          }
          profile_keywords_definition @include(if: $includeKeywordsConfig) {
            id
            name
          }
        }
      }
    }
  }
`);

interface FetchGroupsOptions {
  internal: boolean;
  memberOnly: boolean;
  cursor: string | null;
  pageSize: number;
  sharingExperts: boolean;
  name: string;
  includeStats: boolean;
}

interface SelectGroupProps {
  autoComplete?: boolean;
  autoFocus?: boolean;
  input?: object;
  limit?: number;
  [key: string]: any;
}

const SelectGroup: React.FC<SelectGroupProps & Partial<FetchGroupsOptions>> = (props) => {
  const {
    autoComplete,
    autoFocus,
    input = {},
    limit = 5,
    pageSize = 10,
    internal = false,
    memberOnly = false,
    sharingExperts = false,
    includeStats = false,
    includeBillingAccount = false,
    includeInternalNetwork = false,
    includeKeywordsConfig = true,
    ...other
  } = props;

  const { data, refetch, loading } = useQuery(FETCH_ALL_GROUPS, {
    variables: {
      name: '',
      cursor: '',
      memberOnly,
      internal,
      pageSize,
      memberLimit: limit,
      sharingExperts,
      includeStats,
      includeBillingAccount,
      includeMembers: limit > 0,
      includeInternalNetwork,
      includeKeywordsConfig,
    },
  });

  const groups = (data?.groups?.edges || []).map((e) => e?.node).filter((g) => !!g);

  return (
    <CustomSelect
      loading={loading}
      placeholder="Search team..."
      {...input}
      {...other}
      limit={limit}
      TextFieldProps={{ autoFocus }}
      rawValueOnChange
      id="autoCompleteTeam"
      clearOnBlur
      disableClearable
      autocomplete
      options={groups.map((g) => ({
        value: g.id,
        label: g.name,
      }))}
      onFocus={() => refetch()}
    />
  );
};

export default SelectGroup;
