import React from 'react';

import ConsultationsStarting from '@/components/ConsultationsStarting';
import LayoutPage from '@/components/Layout/LayoutPage';
import WrapperComponent from '@/components/WrapperComponent';
import {
  clearCollection,
  fetchAwaiting,
  fetchCanceled,
  fetchCompleted,
  fetchConfirmed,
} from '@/consultation/store';

import Consultations from './Consultations';

export default {
  path: '/consultations',
  element: <WrapperComponent />,
  async action({ store }: any) {
    const cursor = '';

    store.dispatch(fetchAwaiting(cursor, 10, true));
    store.dispatch(fetchConfirmed(cursor, 10, true));
    store.dispatch(fetchCanceled(cursor, 10, true));
    // @ts-expect-error TS(2554) FIXME: Expected 4 arguments, but got 3.
    store.dispatch(fetchCompleted(cursor, 10, true));
    store.dispatch(clearCollection('default'));

    document.title = 'Consultations';
    return (
      <LayoutPage showNav selected="consultations">
        <ConsultationsStarting />
        <Consultations />
      </LayoutPage>
    );
  },
};
